import {CmpApi} from '@iabtcf/cmpapi';
import {TCModel, TCString, GVL} from '@iabtcf/core';
import { Utils } from './utils.js';
import { Ui2 } from './ui2.js';

Ui2.setUtils(Utils);

/**
 * To create an instance of the CmpApi. Pass in your Cmp ID and the Cmp Version
 * to constructor. Custom commands are optional.
 */
const CMP_ID = 96;
const CMP_VERSION = 2;

const STATUS_1 = 'CONFIG_READY';
const STATUS_2 = 'GVL_READY';
const STATUS_3 = 'LANGUAGE_READY';
var status = '';

/*
var config_ready = false;
var gvl_ready = false;
var language_ready = false;
*/

const custom_commands = {
  'setConfig': (callback, obj) => {
    for (var k in obj) {
      cmpApi.config[k] = obj[k];
    }
    callback(cmpApi);
    
    console.log('config ready');
    //config_ready = true;
    status = STATUS_1;
    checkReady();
  },
  
  'getConfig': (callback) => {
    callback(cmpApi.config);
  }
};

// After creation, window.__tcfapi will respond to "ping" commands and custom commands only. 
// All other commands will be queue until update() is called for the first time.
const cmpApi = new CmpApi(CMP_ID, CMP_VERSION, true, custom_commands);
cmpApi.config = {};

/**
 * During construction of the CmpApi, the __tcfapi stub is replaced with 
 * CmpApi's own function for handling __tcfapi command requests. Commands that 
 * were waiting to be executed in the stub are filtered out if not valid. Ping 
 * and custom commands are executed and removed from the queue while all other 
 * commands remain queued until a valid TCModel is set.
 */

/**
 * Create a valid TCModel and set it in CmpApi. The CmpApi doesn't keep the 
 * reference to the passed in TCModel, it will create it's own deep copy.
 */

// Create a TCModel
var gvl;
var tcModel;
function createGVL() {
  GVL.baseUrl = "https://tcf.shinystat.com/cmp/";
  gvl = new GVL();
  tcModel = new TCModel(gvl);
  
  // Set valid TCModel values here.
  tcModel.isServiceSpecific = true;
  tcModel.cmpId = CMP_ID; 
  tcModel.cmpVersion = CMP_VERSION; 
  
  gvl.readyPromise.then(() => {
    console.log('gvl_ready');
    status = STATUS_2;
    checkReady();
  });
}




const saveFlags = function(type, model) {
  var fname = 'get' + (type.charAt(0).toUpperCase() + type.slice(1));
  var items = Ui2[fname]();
  //console.log(type, model, items);
  model[type].empty();
  model[type].set(items);
}

const onSave = function(customData) {
  saveFlags('purposeConsents', tcModel);
  saveFlags('purposeLegitimateInterests', tcModel);
  saveFlags('vendorConsents', tcModel);
  saveFlags('vendorLegitimateInterests', tcModel);
  saveFlags('specialFeatureOptins', tcModel);
  var customVendors = Ui2.getCustomVendorConsents();
  
  var tcString = TCString.encode(tcModel);
  var ck_value = tcString + '|' + customVendors.join(',');
  //console.log(ck_value);
  Utils.setPermanentCookie(cmpApi.config.cookie_name, ck_value, cmpApi.config.ms_to_expire);
  
  // deprecated cmpApi.tcModel = tcModel;
  //console.log("update...", ck_value);
  console.log(tcString);
  cmpApi.update(tcString, false);
}

// executes if both gvl and config are ready
function checkReady() {
  if (status == STATUS_1) {
    createGVL();
  }

  else if (status == STATUS_2) {
    if (cmpApi.config.language) {
      gvl.changeLanguage(cmpApi.config.language).then(() => {
        console.log('language ready');
        status = STATUS_3;
        checkReady();
      });
    } else {
      status = STATUS_3;
      checkReady();
    }
  }
  
  else if (status == STATUS_3) {
    //console.log("ALL READY");
    //console.log('GVL ready');
    //
    
    //const encodedString = TCString.encode(tcModel);
    //console.log(encodedString); // TC string encoded begins with 'C'

    // Set the TCModel.
    // deprecated: cmpApi.tcModel = tcModel;
    
    gvl.narrowVendorsTo(cmpApi.config.vendors_allowed);
    
    //console.log("CONFIG", cmpApi.config.cookie_name);
    var ck_value = Utils.getPermanentCookie(cmpApi.config.cookie_name, cmpApi.config.ms_to_expire);
    //console.log(ck_value);
    //console.log(cmpApi.config.cookie_name, ck_value);
    try {
      var parts = ck_value.split('|');
      
      // se il cookie non è settato si ferma qui
      var ck_tcModel = TCString.decode(parts[0]);
      
      // se la stringa è settata, aggiorna il modello e indica che NON si sta per visualizzare la UI
      // deprecated cmpApi.tcModel = ck_tcModel;
      cmpApi.update(parts[0], false);
      
      var ck_customVendors = '';
      if (parts.length == 2) {
        ck_customVendors = parts[1].split(',');
      }
      
      /**
       * summon mini layer
       */
       
      Ui2.showBanner(cmpApi.config, gvl, onSave, ck_tcModel, ck_customVendors);
      return;
    
    } catch(err) {
      console.log('[consent string not valid or not set]');
    };
    
    
    
    
    /**
     * summon UI
     */
    
    // deprecated cmpApi.uiVisible = true;
    cmpApi.update('', true);
    Ui2.showBanner(cmpApi.config, gvl, onSave);
    
    //cmpApi.uiVisible = true;
  }
};






/**
 * The CmpApi needs to know when you are going to show the user the CMP UI.
 * Note: You do not have to let CmpApi know when you stop showing the UI as setting the TCModel will handle this.
 */
// Set uiVisible to true. No need to set it to false afterward.
//cmpApi.uiVisible = true;

// ... User makes selections and we update TCModel

//cmpApi.tcModel = tcModel;



/**
 * In the case that GDPR does not apply, simply set the TCModel to null. That's all.
 */
//cmpApi.tcModel = null;



